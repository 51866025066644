import React, { useEffect, useState } from 'react';
import VerificationInput from "react-verification-input";

export default function UserTokenVerification({ onSubmit }) {
  const [token, setToken] = useState('');

  useEffect(() => {
    if (token.length === 6) {
      submitToken(token);
    }
  }, [token]);

  const submitToken = async (givenToken) => {
    onSubmit(givenToken);
    setToken('');
  };

  const verificationInputChanged = (value) => {
    setToken(value);
  };

  return (
    <div role="form">
      <VerificationInput
        autoFocus={true}
        debug={false}
        length={6}
        onChange={verificationInputChanged}
        placeholder="-"
        validChars="0-9"
        value={token}
        inputProps={{ autoComplete: "one-time-code" }}
        classNames={{
          character: 'input border border-light-gray rounded-md 3xs:mx-1 flex justify-center items-center focus:border-primary-blue focus-within:border-primary-blue cursor-pointer text-navy-blue text-lg',
          container: 'w-full tokens my-4 xxs:my-8 flex justify-center items-center h-16 font-avenir-black',
          characterInactive: 'text-light-gray bg-white',
          characterSelected: 'border-primary-blue border-2',
        }}
      />
    </div>
  );
}
