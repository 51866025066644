import React, { useState } from 'react';

import BankAccountDetails from './bank_account/BankAccountDetails';
import BankError from './bank_account/BankError';
import DefaultTermSettings from './DefaultTermSettings';
import ModalFooter from './layout/ModalFooter';
import ModalWrapper from './layout/ModalWrapper';
import useModal from '../../hooks/useModal';

export default function SettingsModalWrapper({
  isModalActive,
  toggleModal,
  modalState,
  refetchCompany,
  modalState: { defaultPM, rechargeSettings },
}) {
  let orderedScreens = [];
  const [loading, setLoading] = useState(false);

  if (!rechargeSettings) orderedScreens.push('recharge_settings');
  if (!defaultPM) orderedScreens = [...orderedScreens, 'bank_account', 'bank_error'];

  const { activeScreen, nextScreen, canNextScreen } = useModal(orderedScreens);

  const modalProps = {
    canNextScreen,
    nextScreen,
    modalState,
    toggleModal,
    refetchCompany,
    loading,
    setLoading,
  };

  const getScreen = () => {
    if (!activeScreen) return null;
    switch (activeScreen) {
      case 'bank_account':
        return <BankAccountDetails {...modalProps} />;
      case 'recharge_settings':
        return <DefaultTermSettings {...modalProps} />;
      case 'bank_error':
        return <BankError {...modalProps} />;
      default:
        return null;
    }
  };

  return (
    <div role={SettingsModalWrapper.role}>
      <ModalWrapper
        toggleModal={toggleModal}
        isModalActive={isModalActive}
        modalOverflowSettings={
          activeScreen === 'bank_account'
            ? 'overflow-y-scroll'
            : 'overflow-y-visible'
        }
        modalHeight={
          activeScreen === 'bank_account'
            ? 'min-h-mobile-modal sm:min-h-modal'
            : 'min-h-recharge'
        }
      >
        <div className="flex flex-col">
          {getScreen()}
          <ModalFooter className="text-sm mt-2" />
        </div>
      </ModalWrapper>
    </div>
  );
}

SettingsModalWrapper.role = 'settings-modal-wrapper-component';
