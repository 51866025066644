import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';

import FullStory from './lib/utils/full_story';
import { platform } from './lib/constants/SiteVariables';
import { mockWorker } from './lib/utils/service_worker_mocks';

const prepare = () => {
  if (process.env.REACT_APP_ENABLE_MOCKING) {
    return mockWorker.start({
      onUnhandledRequest: 'warn',
    });
  } else {
    return Promise.resolve();
  }
};

const fullStory = new FullStory();
fullStory.init(platform());

const container = document.getElementById('root');
const root = createRoot(container);

prepare().then(() => {
  root.render(<App />);
});

if (module.hot) {
  module.hot.accept(function (err) {
    console.log(err, 'An error occurred while accepting new version');
  });
}
