import { Record } from 'immutable';

import { convertRawValues } from '../lib/utils/record_utils';
import CreditLine from './credit_line';
import UserRecord from './user';

export default class CompanyRecord extends Record({
  id: null,
  type: 'company',
  name: '',
  dba: '',
  first_name: '',
  last_name: '',
  owner_email: '',
  underwriting_status: '',
  ein: '',
  industry_description: '',
  latest_applicaton_date: '',
  created_at: '',
  updated_at: '',
  first_decision_date: '',
  next_credit_refresh_on: '',
  fico_score_date: '',
  owners: null,
  owner_count: '',
  gross_annual_revenue: '',
  credit_line: new CreditLine(),
  borrower: new UserRecord(),
  loans: [],
  recharge_settings: [],
}) {
  constructor(data = {}) {
    data = convertRawValues(data);

    if (data.get('credit_line')) {
      data = data.set('credit_line', new CreditLine(data.get('credit_line')));
    }

    if (data.get('borrower')) {
      data = data.set('borrower', new UserRecord(data.get('borrower')));
    }

    super(data);
  }
}
