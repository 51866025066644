import React from 'react';
import classnames from 'classnames';

export function AccountStatus({ status }) {
  let statusColor;
  switch (status) {
    case 'new':
      statusColor = 'text-primary-blue';
      break;
    case 'pending':
    case 'hold':
      statusColor = 'text-warning-yellow';
      break;
    case 'declined':
      statusColor = 'text-negative-red';
      break;
    default:
      statusColor = 'text-gray-500';
      break;
  }

  return (
    <span className={classnames('capitalize', statusColor)}>{status}</span>
  );
}
