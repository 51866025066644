import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CTAButton from '../shared/CTAButton';
import Support from '../shared/Support';

export default function OrderComplete() {
  const navigate = useNavigate();

  return (
    <div className="max-w-3xl mx-auto">
      <div className="min-h-full xxs:py-0 lg:py-4 sm:px-6 lg:px-8">
        <div className="text-center mt-10">
          <div className="mt-6 flex flex-col justify-center items-center">
            <FontAwesomeIcon
              icon={['fas', 'badge-check']}
              className="text-5xl xs:text-6xl text-positive-green"
            />
            <h1 className="text-3xl xs:text-4xl font-avenir-black text-navy-blue my-6">
              Order Complete
            </h1>
            <div className="w-full xs:w-4/5 flex flex-col justify-center items-center px-5 xs:px-0">
              <p className="my-1 text-base xs:text-lg">
                Thank you for using a Credit Key virtual card for your
                purchase.
              </p>
              <p className="text-sm text-gray-700 mt-4 xs:mt-0 mb-4">
                Click below to create a new card if you would like to make
                additional purchases.
              </p>
              <CTAButton
                ariaLabel="back-to-home"
                buttonClass="bg-primary-blue text-white py-2"
                buttonText="Create New Card"
                containerClass="my-2 lg:my-4 w-full xs:w-2/5 sm:mx-auto"
                onClick={() => navigate('/')}
              />
            </div>
          </div>
        </div>
        <div className="mt-10 xs:mt-4 text-center text-dark-gray">
          <Support />
        </div>
      </div>
    </div>
  );
}
