import React from 'react';
import { supportPhone } from '../../lib/constants/SiteVariables';

export default function Support() {
  return (
    <div className="mt-2 text-sm px-2 3xs:px-0">
      Questions? Issues? <span className='block 3xs:inline'>Please contact Credit Key Support:<br /></span>
      <a href="mailto: support@creditkey.com" className='underline'>support@creditkey.com</a> or {supportPhone}
    </div>
  );
}
