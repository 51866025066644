import classNames from 'classnames';

export default function Container({ children, utilClasses }) {
  return (
    <div className={classNames('mx-auto h-full', utilClasses)} role={Container.role}>
      {children}
    </div>
  );
}

Container.role = 'container';
