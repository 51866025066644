import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Dropdown({ buttonText, children }) {
  return (
    <Menu as="div" className="relative inline-block text-left w-4/5">
      <div className="w-full mx-auto">
        <Menu.Button className="inline-flex w-full justify-center items-center gap-4 rounded-md bg-border-gray px-4 py-2 text-sm text-gray-900 hover:bg-gray-200" role='dropdown-menu-button'>
          <span className="w-full">{buttonText}</span>
          <FontAwesomeIcon icon={['far', 'chevron-down']} className="" />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="z-20 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-xl bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="px-1 py-1 divide-y divide-gray-100">{children}</div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}