import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Support from '../shared/Support';

export default function InvalidMerchant() {
  return (
    <div id="content" className="text-center my-6">
      <div className="text-3xl font-avenir-black text-negative-red mb-4">
        <FontAwesomeIcon icon="circle-xmark" />
        <p className="mt-4">Invalid Merchant</p>
      </div>

      <div className="max-w-[400px] mx-auto leading-7 font-avenir-medium my-4">
        <p>
          This merchant is not currently authorized for Virtual Card Instore
          Orders.
        </p>
        <p>
          Please contact your merchant for a new order link or reach out to
          Credit Key Support for further assistance.
        </p>
      </div>
      <Support />
    </div>
  );
}
