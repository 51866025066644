import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AuthContext, UserContext } from '../../App';

export default function AuthButton() {
  const navigate = useNavigate();
  const { setAuth } = useContext(AuthContext);
  const { setUser } = useContext(UserContext);

  return (
    <div
      className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base text-white bg-primary-blue hover:bg-navy-blue"
      onClick={() => {
        setAuth(null);
        setUser(null);
        window.localStorage.removeItem('auth');
        navigate('/login');
      }}
      role={AuthButton.role}
    >
      <span>Sign out</span>
      <FontAwesomeIcon
        icon={['fal', 'arrow-right-from-bracket']}
        className="shrink-0 mx-2 text-sm cursor-pointer hover:text-primary-blue"
      />
    </div>
  );
}

AuthButton.role = 'auth-button-component';
