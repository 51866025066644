import { useContext, useReducer, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthContext, UserContext } from '../App';
import useToast from './useToast';

const errorReducer = (state, action) => {
  switch (action.type) {
    case 'SET_ERROR':
      return { error: action.payload };
    case 'CLEAR_ERROR':
      return { error: null };
    default:
      return state;
  }
};

function useErrorHandling() {
  const navigate = useNavigate();
  const { setAuth } = useContext(AuthContext);
  const { setUser } = useContext(UserContext);
  const { makeToast } = useToast();
  const [error, setError] = useState();

  const [errorState, dispatch] = useReducer(errorReducer, { error: null });

  const getErrorMsg = (msg) => {
    switch (msg) {
      case 'Purchase amount is not authorized':
        return "Purchase amount is not authorized. Please lower amount below customer's approved credit limit.";
      case 'Borrower not approved':
        return "Customer not approved to purchase with Credit Key. Please contact Credit Key support for further assistance.";
      case 'Merchant order id already exists':
        return 'Merchant Order ID already exists. Please update and submit again.';
      case 'User not found':
        // TODO: send a link to an application
        return 'User not found. Please update the phone number or check the box below to send the user a Credit Key application.';
      default:
        return 'An error occurred. Please contact Credit Key support for assistance.';
    }
  };

  useEffect(() => {
    if (errorState.error) {
      if (errorState.error.code === 401) {
        makeToast(
          'You are being logged out due to session refresh',
          'negative-red',
          ['far', 'octagon-xmark'],
        );
        setAuth(null);
        setUser(null);
        setTimeout(() => {
          window.location.href = '/login';
        }, 2000);
      }
      if (errorState.error.code === 500) {
        makeToast(
          'Whoops, something went wrong! Please contact Credit Key Support for assistance: (844) 334-3636',
          'negative-red',
          ['far', 'octagon-xmark'],
        );
        navigate('/support');
      }
      setError(getErrorMsg(errorState.error.msg));
    } else {
      dispatch({ type: 'CLEAR_ERROR' });
      setError(errorState.error)
    }

  }, [errorState.error]);

  return { dispatch, error };
}

export default useErrorHandling;
