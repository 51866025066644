import React, { useRef, useContext, useState } from 'react';
import classnames from 'classnames';
import { Formik, Form } from 'formik';
import { useQuery, useMutation } from '@tanstack/react-query';

import CTAButton from '../../shared/CTAButton';
import ColumnInput from '../../shared/ColumnInput';
import Loading from '../../shared/Loading';
import PaymentMethodRecord from '../../../records/payment_method';
import StickyCheckbox from '../../shared/StickyCheckbox';
import StickyInput from '../../shared/StickyInput';
import useToast from '../../../hooks/useToast';
import { ApiContext, UserContext } from '../../../App';
import {
  createPaymentMethodBank,
  generateBankAgreement,
} from '../../../actions/payment_methods';

export default function BankForm({ toggleModal, refetchCompany, nextScreen }) {
  const form = useRef(null);
  const api = useContext(ApiContext);
  const { user } = useContext(UserContext);
  const { makeToast } = useToast();
  const [loading, setLoading] = useState(false);

  const {
    data: bankAgreement,
    error: bankAgreementError,
    isLoading: bankAgreementLoading,
  } = useQuery({
    queryKey: ['bank_agreement', user?.id],
    queryFn: () => generateBankAgreement(api, user),
    options: {
      enabled: !!user,
      retry: false,
    },
  });

  const savePaymentMethod = useMutation({
    mutationFn: async ({ api, values }) => {
      return createPaymentMethodBank(api, values);
    },
    onError: async (error, variables) => {
      const { setFieldError } = variables;
      const invalid = error.response?.data?.error === 'invalid_bank';
      const excessive = error.response?.data?.error === 'excessive_attempts';

      if (excessive) {
        nextScreen();
      } else if (invalid) {
        setFieldError(
          'bank_account',
          'Double check the account number and try again.',
        );
        setFieldError(
          'bank_routing',
          'Double check the routing number and try again.',
        );
      }
    },
    onSuccess: async (res, variables) => {
      const { resetForm } = variables;
      refetchCompany();
      makeToast(
        'New Payment Method Added!',
        'bg-positive-green',
        'check-circle',
      );
      resetForm();
      toggleModal();
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  const onSubmit = async (values, { setFieldError, resetForm }) => {
    setLoading(true);
    await savePaymentMethod.mutateAsync({
      api,
      values: new PaymentMethodRecord(values),
      setFieldError,
      resetForm,
    });
  };

  return (
    <Formik
      innerRef={form}
      initialValues={{
        agree: '',
        bank_routing: '',
        bank_account: '',
        account_holder: '',
        user_id: user.id,
      }}
      validate={async (values) => {
        const errors = {};
        if (!values.agree) errors.agree = '* Required';
        if (!values.bank_routing) {
          errors.bank_routing = '* Required';
        } else if (values.bank_routing.trim().length < 9) {
          errors.bank_routing = 'Routing No. must be exactly 9 characters long';
        }
        if (!values.account_holder) errors.account_holder = '* Required';
        if (!values.bank_account) errors.bank_account = '* Required';
        return errors;
      }}
      onSubmit={onSubmit}
    >
      {({ values, errors, setFieldValue }) => (
        <Form role="form">
          <div className="flex flex-col m-auto w-4/5">
            <ColumnInput error={errors.bank_routing}>
              <StickyInput
                id="bank_routing"
                inputMode="numeric"
                mask="999999999"
                placeholder="Routing Number"
                values={values}
              />
            </ColumnInput>
            <ColumnInput error={errors.bank_account}>
              <StickyInput
                id="bank_account"
                inputMode="numeric"
                mask={/^\d*$/}
                placeholder="Account Number"
                values={values}
              />
            </ColumnInput>
            <ColumnInput error={errors.account_holder}>
              <StickyInput
                id="account_holder"
                placeholder="Name on Account"
                values={values}
              />
            </ColumnInput>
            {bankAgreementLoading ? (
              <div className="w-full flex items-center justify-center py-14 border border-solid border-opaque-navy-border rounded">
                <Loading className="w-14 h-14 mr-2 mt-1 text-opaque-navy/30" />
              </div>
            ) : (
              <div
                className={classnames(
                  'form-control block w-full p-3 text-sm font-normal bg-white bg-clip-padding border border-solid border-opaque-navy-border rounded transition ease-in-out m-0 focus:text-navy-blue focus:bg-white focus:border-navy-blue focus:outline-none h-40 max-h-40 overflow-y-scroll',
                  bankAgreementError ? 'text-negative-red' : 'text-opaque-navy',
                )}
                dangerouslySetInnerHTML={{
                  __html: bankAgreementError
                    ? 'Unable to generate bank agreement. Please contact Credit Key Support for further assistance.'
                    : bankAgreement,
                }}
              />
            )}
            <div className="mb-0">
              <div>
                <div className="flex justify-center my-2">
                  <StickyCheckbox
                    id="agree"
                    placeholder="I authorize Credit Key to charge scheduled payments to my bank account."
                    errors={errors}
                    setFieldValue={setFieldValue}
                  />
                </div>
              </div>
            </div>
            <CTAButton
              ariaLabel="save-payment-method"
              buttonClass="bg-primary-blue text-white"
              buttonText="Save Payment Method"
              containerClass="my-2 lg:my-4 w-full sm:mx-auto"
              disabled={!!Object.keys(errors).length || loading}
              icon={['far', 'lock']}
              isLoading={loading}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
}
