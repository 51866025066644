import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useQuery } from '@tanstack/react-query';

import { ApiContext } from '../App';
import { getMerchant } from '../actions/merchants';
import { useEntities } from './useEntities';
import { subDomains } from '../lib/constants/SiteVariables';

function useMerchant() {
  const navigate = useNavigate();
  const api = useContext(ApiContext);
  const { state, setOne } = useEntities();

  const {
    data: merchant,
    error: merchantError,
    isLoading: merchantLoading,
  } = useQuery({
    queryKey: [
      'merchant',
      subDomains.slug || process.env.REACT_APP_INSTORE_DEFAULT_SLUG,
    ],
    queryFn: () =>
      getMerchant(
        api,
        subDomains.slug || process.env.REACT_APP_INSTORE_DEFAULT_SLUG,
      ),
    enabled: !state.get('merchant').size,
    retry: false,
  });

  useEffect(() => {
    if (merchant) {
      merchant?.attributes?.supports_vcinstore
        ? setOne(merchant)
        : navigate('/invalid_merchant');
    }
  }, [merchant]);

  return { merchant: merchant?.attributes, merchantError, merchantLoading };
}

export default useMerchant;
