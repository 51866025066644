import toast from 'react-hot-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function useToast() {
  const makeToast = (msg, color, icon) => {
    toast(
      <div
        className={`${color} absolute top-3 right-3 shadow-lg mx-auto w-96 max-w-full text-sm pointer-events-auto bg-clip-padding rounded-lg block mb-3`}
        id="static-example"
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
        data-mdb-autohide="false"
      >
        <div
          className={`${color} flex justify-between items-center py-2 px-3 bg-clip-padding border-b border-${color} rounded-lg`}
        >
          <div className="flex items-center">
            <FontAwesomeIcon icon={icon} className="text-white mr-4 text-lg" />
            <p className="font-bold text-white mt-0.5">{msg}</p>
          </div>
        </div>
      </div>,
    );
  };

  return {
    makeToast,
  };
}
