import React from 'react';
import { platform } from '../../lib/constants/SiteVariables';
import classNames from 'classnames';

export default function ZendeskChatLink({ msg, onClick = () => { }, className }) {
  const zdAvailable = (window.zEACLoaded || false) && platform() !== 'development';

  const openChat = () => {
    if (zdAvailable) {
      window.zE('messenger', 'open');
    } else {
      console.warn('The chat widget is not fully loaded');
    }
  };

  const chatText = () => {
    let text = msg || 'Connect with live chat';
    return text;
  };

  return (
    <span
      onClick={() => {
        openChat();
        onClick();
      }}
      className={classNames("cursor-pointer underline", className)}
    >
      {chatText()}
    </span>
  );
}