import { useContext } from 'react';
import { EntitiesContext } from '../App';
import * as ActionTypes from '../lib/constants/action-types';

export const useEntities = () => {
  const entities = useContext(EntitiesContext);

  return {
    dispatch: entities?.dispatch,
    state: entities?.state,
    clearOne: payload => entities?.dispatch({
      type: ActionTypes.CLEAR_ENTITY,
      payload: payload
    }),
    clearMany: payload => entities?.dispatch({
      type: ActionTypes.CLEAR_ENTITIES,
      payload: payload
    }),
    setOne: data => entities?.dispatch({
      type: ActionTypes.RECEIVE_ENTITY,
      payload: data
    }),
    setMany: data => entities?.dispatch({
      type: ActionTypes.RECEIVE_ENTITIES,
      payload: data
    })
  };
};
