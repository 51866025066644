import { useContext, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { useMutation } from '@tanstack/react-query';

import UserRecord from '../records/user';
import useToast from './useToast';
import { ApiContext, AuthContext, UserContext } from '../App';

const needsRefresh = (token) => {
  const expires = DateTime.fromSeconds(token.created_at)
    .plus({ hours: 2 })
    .setZone(DateTime.local().zoneName);

  return DateTime.now().setZone(DateTime.local().zoneName) >= expires;
};

export const useCreateToken = () => {
  const api = useContext(ApiContext);

  return useMutation({
    mutationFn: (contact) =>
      api.post('/api/users/verify', {
        type: 'phone',
        contact: contact.contact,
      }),
    options: {
      onError: (error) => console.log(error),
    },
  });
};

export const useVerifyToken = () => {
  const api = useContext(ApiContext);

  return useMutation({
    mutationFn: (payload) => api.post('/api/users/verified', payload),
  });
};

export const useSetAuth = (data) => {
  const auth = useContext(AuthContext);
  const user = useContext(UserContext);

  useEffect(() => {
    if (!auth.auth && data && data.authorized && !user.user) {
      auth.setAuth(data);
      user.setUser(new UserRecord({ ...data.user }));
      window.localStorage.setItem('auth', JSON.stringify(data));
    }
  }, [data]);

  return data;
};

export const useVerifyAuth = () => {
  const auth = useContext(AuthContext);
  const user = useContext(UserContext);
  const saved = JSON.parse(window.localStorage.getItem('auth'));
  const { makeToast } = useToast();
  const [loading, setLoading] = useState(false);

  if (!auth || (saved && needsRefresh(saved.token) && !loading)) {
    setLoading(true);
    makeToast('You are being logged out due to inactivity', 'bg-negative-red', [
      'far',
      'octagon-xmark',
    ]);
    auth.setAuth(null);
    user.setUser(null);
    setTimeout(() => {
      setLoading(false);
      window.location.href = '/login';
    }, 2000);
  }

  const data = useSetAuth(saved || null);
  return data;
};
