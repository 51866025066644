import { Record } from 'immutable';

export default class PaymentMethodRecord extends Record({
  id: undefined,
  user_id: null,
  account_id: null,
  account_holder: '',
  bank_name: '',
  bank_account: '',
  bank_routing: '',
  label: '',
  is_default: false,
  status: '',
  payment_type: '',
  processor_data: undefined,
}) {}
