import { Record } from 'immutable';

export default class ApprovedTermRecord extends Record({
  id: null,
  available: true,
  monthly_payment: '',
  rate: '',
  term: '',
  term_period: '',
  tier: '',
  total_amount: '',
  total_fees: '',
}) {}
