import classNames from "classnames";

const protocol = "https://";
const rootURI = "creditkey.com";

export const VERSION = "1.0.0";

export const platform = () => {
  return process.env.REACT_APP_ENV;
};

export const server = (api = true) => {
  if (process.env && process.env.NODE_ENV !== "production")
    return "http://localhost:9100";

  let subDomain = "www";
  if (window.location && window.location.hostname.match(/staging/i))
    subDomain = "staging";

  const baseURI = protocol + subDomain + "." + rootURI;

  return api ? `${baseURI}/app` : baseURI;
};

export const subDomains = {
  authorized: () => window.location.hostname.split('.')[1] === 'cards',
  slug: window.location.hostname.split('.')[0],
};

export const borrowerPortalUrl = () => `${server()}/dashboard/borrower`;
export const merchantPortalUrl = () => `${server()}/dashboard/company`;

export const dateFormat = "YYYY-MM-DD HH:MM:SSZ";

export const formStyles = {
  error: (error) =>
    classNames("input", {
      "border-negative-red border-opacity-50": error,
    }),
  input: (error) =>
    classNames("mb-3 w-full", {
      "mb-2": error,
    }),
  validation:
    "text-negative-red mt-1 text-xs",
};

export const supportPhone = '+1 (844) 334-3636';

export const suffixes = [
  ["", ""],
  ["Jr", "Jr"],
  ["Sr", "Sr"],
  ["II", "II"],
  ["III", "III"],
  ["IV", "IV"],
  ["V", "V"],
  ["DDM", "DDM"],
  ["DDS", "DDS"],
  ["DO", "DO"],
  ["ESQ", "ESQ"],
  ["MD", "MD"],
  ["RN", "RN"],
];

export const business_types = [
  ["", ""],
  ["Corporation", "Corporation"],
  ["LLC", "LLC"],
  ["Sole Proprietorship", "Sole Proprietorship"],
  ["Partnership", "Partnership"],
  ["Nonprofit corporation", "Nonprofit corporation"],
  ["Federal", "Federal"],
  ["Municipal", "Municipal"],
]

export const states = [
  ["", ""],
  ["Alabama", "AL"],
  ["Alaska", "AK"],
  ["Arizona", "AZ"],
  ["Arkansas", "AR"],
  ["California", "CA"],
  ["Colorado", "CO"],
  ["Connecticut", "CT"],
  ["Delaware", "DE"],
  ["Florida", "FL"],
  ["Georgia", "GA"],
  ["Hawaii", "HI"],
  ["Idaho", "ID"],
  ["Illinois", "IL"],
  ["Indiana", "IN"],
  ["Iowa", "IA"],
  ["Kansas", "KS"],
  ["Kentucky", "KY"],
  ["Louisiana", "LA"],
  ["Maine", "ME"],
  ["Maryland", "MD"],
  ["Massachusetts", "MA"],
  ["Michigan", "MI"],
  ["Minnesota", "MN"],
  ["Mississippi", "MS"],
  ["Missouri", "MO"],
  ["Montana", "MT"],
  ["Nebraska", "NE"],
  ["Nevada", "NV"],
  ["New Hampshire", "NH"],
  ["New Jersey", "NJ"],
  ["New Mexico", "NM"],
  ["New York", "NY"],
  ["North Carolina", "NC"],
  ["North Dakota", "ND"],
  ["Ohio", "OH"],
  ["Oklahoma", "OK"],
  ["Oregon", "OR"],
  ["Pennsylvania", "PA"],
  ["Rhode Island", "RI"],
  ["South Carolina", "SC"],
  ["South Dakota", "SD"],
  ["Tennessee", "TN"],
  ["Texas", "TX"],
  ["Utah", "UT"],
  ["Vermont", "VT"],
  ["Virginia", "VA"],
  ["Washington", "WA"],
  ["Washington DC", "DC"],
  ["West Virginia", "WV"],
  ["Wisconsin", "WI"],
  ["Wyoming", "WY"],
]

// eslint-disable-next-line
export const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
