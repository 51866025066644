import React, { useState } from 'react';
import classnames from 'classnames';
import { ErrorMessage, Field } from 'formik';

import { formStyles } from '../../lib/constants/SiteVariables';

export default function StickyCheckbox({
  id,
  errors,
  placeholder,
  setFieldValue,
}) {
  let [checked, setChecked] = useState(false);

  const toggleChecked = () => {
    setChecked(!checked);
  };

  return (
    <>
      <Field
        className={classnames('form-check-input appearance-none h-4 w-4 border border-black border-opacity-20 rounded-sm bg-white checked:bg-primary-blue checked:border-primary-blue focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer flex justify-center', {
          'border-warning-red': errors[id],
        })}
        type="checkbox" 
        id={id}
        name={id}
        onChange={(e) => {
          setFieldValue(id, e.currentTarget.checked);
          toggleChecked();
        }}
      />
      <label htmlFor={id} className="text-base mx-2">
        <span
          className={classnames('text-sm', {
            'font-avenir-medium': checked,
          })}
        >
          {placeholder}
        </span>
        <ErrorMessage
          name={id}
          component="div"
          className={formStyles.validation}
        />
      </label>
    </>
  );
}
