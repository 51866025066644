import React from 'react';
import { useField } from 'formik';
import { IMaskInput } from 'react-imask';

export const MaskedField = ({ ...props }) => {
  const [field, meta, helpers] = useField(props.name);

  // quack like a Formik `Field`, but with masking!
  return (
    <IMaskInput
      {...field}
      {...props}
      onAccept={(value, mask) => helpers.setValue(value)}
      onComplete={(value, mask) => helpers.setValue(value)}
      unmask={false}
      // defining '9' for backcompat with existing masks
      definitions={{ 9: /\d/ }}
    />
  );
};
