import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router';
import { useQuery } from '@tanstack/react-query';

import AccountDetails from './AccountDetails';
import Loading from '../../shared/Loading';
import SettingsModalWrapper from '../../modals/SettingsModalWrapper';
import Support from '../../shared/Support';
import useCompany from '../../../hooks/useCompany';
import useMerchant from '../../../hooks/useMerchant';
import useModal from '../../../hooks/useModal';
import usePaymentMethods from '../../../hooks/usePaymentMethods';
import { ApiContext, UserContext } from '../../../App';
import { getApprovedTerms } from '../../../actions/companies';
import { useEntities } from '../../../hooks/useEntities';

export default function Home() {
  const navigate = useNavigate();
  const api = useContext(ApiContext);
  const { user } = useContext(UserContext);
  const { state, setMany } = useEntities();
  const { toggleModal, isModalActive } = useModal();
  const { merchant, merchantError, merchantLoading } = useMerchant();
  const { defaultPM, pmError, pmLoading } = usePaymentMethods(user);
  const {
    company,
    companyError,
    companyLoading,
    companyRefetching,
    refetchCompany,
  } = useCompany(user);

  const rechargeSettings = company?.recharge_settings.find(
    (s) => s.merchant_id === merchant?.id,
  );

  const { data: approvedTerms } = useQuery({
    queryKey: ['approved_terms', company?.id],
    queryFn: () => getApprovedTerms(api, company?.id, merchant?.id),
    enabled: !!company && !!merchant && !state?.get('approved_term')?.size,
    retry: false,
  });

  const props = {
    company,
    companyError,
    companyRefetching,
    defaultPM,
    pmError,
    rechargeSettings,
    toggleModal,
  };

  useEffect(() => {
    if ([401, 404].includes(merchantError?.response?.status)) {
      navigate('/invalid_merchant');
    }
    if (approvedTerms) setMany(approvedTerms);
  }, [approvedTerms, merchantError, navigate]);

  if (companyLoading || pmLoading || !user || merchantLoading)
    return <Loading className="w-20 h-20" containerClass="mt-10" />;

  return (
    <div className="max-w-3xl mx-auto">
      {(!defaultPM || !rechargeSettings) && (
        <SettingsModalWrapper
          modalState={{
            company,
            merchant,
            defaultPM,
            rechargeSettings,
          }}
          refetchCompany={refetchCompany}
          isModalActive={isModalActive}
          toggleModal={toggleModal}
        />
      )}
      <div className="min-h-full flex flex-col justify-center xxs:py-0 lg:py-4 sm:px-6 lg:px-8">
        <div className="bg-white mt-0 xs:mt-4 mb-4 px-10 md:shadow md:rounded-lg sm:px-14 md:py-10">
          <div className="text-center">
            <div className="my-6">
              <h1 className="text-xl xs:text-3xl font-avenir-black text-navy-blue">
                Pay for purchases with Credit Key
              </h1>
              <p className="my-1 text-base xs:text-lg">
                View virtual card below to checkout with {merchant?.name}
              </p>
              <p className="text-xs w-4/5 mx-auto">
                *‍Valid on business purchases only for approved Credit Key
                customers and subject to customer's approved Credit Key Trade
                Credit.
              </p>
            </div>
            <AccountDetails props={props} />
          </div>
          <div className="mt-8 text-center text-dark-gray">
            <Support />
          </div>
        </div>
      </div>
    </div>
  );
}
