import { useEffect, useContext, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { ApiContext } from '../App';
import { getCompanyPaymentMethods } from '../actions/companies';
import { useEntities } from './useEntities';

function usePaymentMethods(user) {
  const api = useContext(ApiContext);
  const { setMany } = useEntities();
  const [defaultPM, setDefaultPM] = useState();

  const {
    data: payment_methods,
    error: pmError,
    isLoading: pmLoading,
  } = useQuery({
    queryKey: ['payment_method', user?.email],
    queryFn: () =>
      getCompanyPaymentMethods(
        api,
        `filter[email]=${encodeURIComponent(user?.email)}`,
      ),
    enabled: !!user,
    retry: false,
  });

  useEffect(() => {
    if (payment_methods) {
      setMany(payment_methods);
      setDefaultPM(
        payment_methods?.data?.find(
          (pm) =>
            pm.attributes.is_default &&
            pm.attributes.payment_type === 'bank_account',
        ),
      );
    }
  }, [payment_methods]);

  return {
    defaultPM: defaultPM?.attributes,
    pmError,
    pmLoading,
  };
}

export default usePaymentMethods;
