import React, { useContext, useState } from 'react';
import classnames from 'classnames';
import { useMutation } from '@tanstack/react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CreditCardDisplay from './CreditCardDisplay';
import useMerchant from '../../../hooks/useMerchant';
import useToast from '../../../hooks/useToast';
import { ApiContext, UserContext } from '../../../App';
import { createVCInstoreOrder } from '../../../actions/orders';

export default function NextStepButton({
  company,
  companyRefetching,
  defaultPM,
  rechargeSettings,
  loading,
  setLoading,
  toggleModal,
}) {
  let buttonText;
  let onClick;
  const api = useContext(ApiContext);
  const { user } = useContext(UserContext);
  const { makeToast } = useToast();
  const { merchant } = useMerchant();
  const [showCard, setShowCard] = useState(false);
  const [virtualCardDetails, setVirtualCardDetails] = useState();
  const toggleCardDisplay = () => setShowCard(!showCard);

  if (!rechargeSettings || !defaultPM) {
    buttonText = 'Add Payment Settings';
    onClick = toggleModal;
  } else {
    buttonText = 'Generate New Virtual Card';
    onClick = () => {
      setLoading(true);
      getVCDetails
        .mutateAsync({
          api,
          order: {
            user_id: user?.id,
            merchant_id: merchant?.id,
          },
        })
        .catch((err) => console.log(err));
    };
  }

  const getVCDetails = useMutation({
    mutationFn: ({ api, order }) => createVCInstoreOrder(api, order),
    onError: async () => {
      makeToast(
        'An error occurred. Please contact Credit Key Support',
        'bg-negative-red',
        ['far', 'octagon-xmark'],
      );
    },
    onSuccess: async (res) => {
      setVirtualCardDetails(res.virtual_card_details);
      toggleCardDisplay();
    },
    onSettled: async () => {
      setLoading(false);
    },
  });

  if (company?.underwriting_status !== 'approved') return;

  return (
    <div role={NextStepButton.role}>
      <button
        disabled={companyRefetching}
        onClick={onClick}
        className={classnames(
          'rounded-md bg-primary-blue px-4 py-2 text-white shadow-sm hover:bg-navy-blue min-w-[50%]',
          {
            'cursor-not-allowed bg-opacity-50 hover:bg-opacity-50':
              companyRefetching,
          },
        )}
      >
        {
          <div className="flex items-center justify-center">
            {(companyRefetching || loading) && (
              <FontAwesomeIcon className="mx-2 mb-0.5" icon="spinner" spin />
            )}
            {buttonText}
          </div>
        }
      </button>
      {showCard && (
        <CreditCardDisplay
          setShowCard={setShowCard}
          showCard={showCard}
          toggleCardDisplay={toggleCardDisplay}
          virtualCardDetails={virtualCardDetails}
        />
      )}
    </div>
  );
}

NextStepButton.role = 'next-step-button'