import { FullStory as FS, init as initFullStory } from '@fullstory/browser';

export default class FullStory {
  constructor(component = '') {
    if (component) this.component = component?.toLowerCase();
  }

  init(platform) {
    initFullStory({ orgId: 'Y34CM', devMode: platform !== 'production' });
  }

  identify(id, vars = {}) {
    if (process.env.NODE_ENV !== 'production') return false;

    FS('setIdentity', {
      uid: id,
      properties: vars,
    });
  }

  event({ user = {}, company = {} }) {
    if (process.env.NODE_ENV !== 'production') return false;

    FS('trackEvent', {
      name: 'VCInstore Portal',
      properties: { email: user.email },
    });
  }
}
