import { setupWorker } from 'msw';
import { auth, get, post, put } from '../testing/mocks/handlers';
import * as records from '../testing/mocks/records';

export const mockWorker = setupWorker(
  ...[
    auth.verify(records.user.email, 'email'),
    auth.verified(records.user, 273338),
    get.company(records.companyApproved),
    get.merchant(records.vcMerchant),
    get.payment_methods(records.user, [
      records.paymentMethodBank1,
      records.paymentMethodBank2,
    ]),
    post.virtual_card(records.vcOrder),
    post.payment_method(records.paymentMethodBank1),
    post.generate_bank_agreement(records.user),
    post.approved_terms(records.companyApproved, records.vcMerchant, [
      records.approvedTerm1,
      records.approvedTerm2,
      records.approvedTerm3,
      records.approvedTerm4,
      records.approvedTerm5,
      records.approvedTerm6,
    ]),
    put.company(records.companyApproved),
  ],
);
