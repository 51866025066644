import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Toaster } from 'react-hot-toast';

import Container from './Container';
import TopNav from './TopNav';
import useErrorHandling from '../../hooks/useErrorHandling';
import { subDomains } from '../../lib/constants/SiteVariables';

export default function PageLayout({ children }) {
  const navigate = useNavigate();

  useEffect(() => {
    if (!subDomains.authorized()) navigate('/unauthorized');
  }, [navigate]);

  useErrorHandling();

  return (
    <div className="h-screen font-avenir-book" role={PageLayout.role}>
      <TopNav />
      <Container utilClasses="w-full">
        <Toaster
          position="top-right"
          reverseOrder={false}
          gutter={8}
          toastOptions={{
            duration: 6000,
            style: {
              boxShadow: 'none',
              width: '100%',
              background: 'none',
            },
          }}
        />
        <div>{children}</div>
      </Container>
    </div>
  );
}

PageLayout.role = 'page-layout-component';
