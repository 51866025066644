import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useCreateToken } from '../../hooks/useAuth';

import logo from '../../lib/images/logo.svg';
import UserVerification from '../shared/UserVerification';
import { subDomains } from '../../lib/constants/SiteVariables';

export default function Login() {
  const navigate = useNavigate();
  const auth = useCreateToken();
  const [loading, setLoading] = useState(false);

  const save = (values, input_type) => {
    setLoading(true);
    auth.mutateAsync(values).then(() => {
      setLoading(false);
      navigate(`/login-token/${input_type}/${values.contact}`);
    });
  };

  return (
    <div
      className="min-h-full flex flex-col justify-center xxs:py-0 lg:py-4 sm:px-6 lg:px-8"
      role={Login.role}
    >
      <div className="mt-8 xxs:mx-auto xxs:w-full xxs:max-w-lg">
        <div className="bg-white my-4 xxs:py-10 sm:py-20 px-10 sm:shadow sm:rounded-lg sm:px-14 text-center">
          <div className="sm:flex justify-center mb-8 hidden">
            <img src={logo} alt="Credit Key logo" />
          </div>
          <h2 className="my-2 text-3xl font-avenir-black text-navy-blue">
            Welcome to Credit Key
          </h2>
          <p className="my-2 text-base font-avenir-book text-opaque-navy">
            Please sign in. We'll send a 6-digit code to you.
          </p>
          <UserVerification
            defaultValue=""
            buttonText="Sign in"
            onSubmit={save}
            loading={loading}
          />
          <div className="my-4 text-sm">
            <p>Don't have a Credit Key Account?</p>
            <Link
              to={`${process.env.REACT_APP_INSTORE_CHECKOUT_URL}/${subDomains.slug}`}
              target="_blank"
              className="text-primary-blue font-avenir-medium underline hover:cursor-pointer"
            >
              Apply Now
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

Login.role = 'login-component';
