import React, { useContext, useState, createContext, useReducer } from 'react';
import axios from 'axios';
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Navigate,
  useNavigate,
} from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { library } from '@fortawesome/fontawesome-svg-core';

import Home from './components/pages/home/Index';
import InvalidMerchant from './components/shared/InvalidMerchant';
import LockedOut from './components/pages/LockedOut';
import Login from './components/pages/Login';
import LoginTokenForm from './components/pages/LoginToken';
import PageLayout from './components/layout/PageLayout';
import OrderComplete from './components/pages/OrderComplete';
import Support from './components/pages/SupportError';
import Unauthorized from './components/shared/Unauthorized';
import entities, { entitiesState } from './reducers/entities';
import { useVerifyAuth } from './hooks/useAuth';
import {
  faAngleDown,
  faBars,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faExclamationTriangle,
  faLock,
  faOctagonXmark,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faBadgeCheck,
  faCircleXmark,
  faEllipsisVertical,
  faHouseBlank,
  faSpinner,
  faTimes,
} from '@fortawesome/pro-solid-svg-icons';
import {
  faArrowRightFromBracket,
  faArrowRightToBracket,
  faCircleQuestion,
} from '@fortawesome/pro-light-svg-icons';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

export const ApiContext = createContext();
export const AuthContext = createContext();
export const EntitiesContext = createContext();
export const UserContext = createContext();

export const queryClient = new QueryClient();

library.add(
  faAngleDown,
  faArrowRightFromBracket,
  faArrowRightToBracket,
  faBars,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCircleQuestion,
  faCircleXmark,
  faEllipsisVertical,
  faExclamationTriangle,
  faHouseBlank,
  faLock,
  faOctagonXmark,
  faSpinner,
  faTimes,
  faXmark,
  faBadgeCheck
);

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

function App() {
  const [auth, setAuth] = useState(null);
  const [user, setUser] = useState(null);
  const [state, dispatch] = useReducer(entities, entitiesState);

  return (
    <QueryClientProvider client={queryClient}>
      <AuthContext.Provider value={{ auth, setAuth }}>
        <ApiContext.Provider
          value={axios.create({
            baseURL: process.env.REACT_APP_API,
            headers: headers,
          })}
        >
          <EntitiesContext.Provider value={{ state, dispatch }}>
            <UserContext.Provider value={{ user, setUser }}>
              <BrowserRouter>
                <PageLayout>
                  <Routes>
                    <Route
                      exact="true"
                      path="/"
                      element={
                        <RequireAuth>
                          <Home />
                        </RequireAuth>
                      }
                    />
                    <Route
                      exact="true"
                      path="/order_complete"
                      element={
                        <RequireAuth>
                          <OrderComplete />
                        </RequireAuth>
                      }
                    />
                    <Route path="/login" element={<Login />} />
                    <Route path="/support" element={<Support />} />
                    <Route path="/user_lockout" element={<LockedOut />} />
                    <Route path="/invalid_merchant" element={<InvalidMerchant />} />
                    <Route path="/unauthorized" element={<Unauthorized />} />
                    <Route
                      path="/login-token/:type/:contact"
                      element={<LoginTokenForm />}
                    />
                  </Routes>
                </PageLayout>
              </BrowserRouter>
            </UserContext.Provider>
          </EntitiesContext.Provider>
        </ApiContext.Provider>
      </AuthContext.Provider>
    </QueryClientProvider>
  );
}

function RequireAuth({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const api = useContext(ApiContext);
  const auth = useVerifyAuth();

  if (!auth) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  api.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response.status === 403) navigate('/');

      return Promise.reject(error);
    },
  );
  api.defaults.headers.common['Authorization'] =
    `Bearer ${auth?.token.access_token}`;

  return children;
}

export default App;
