import React, { useContext, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useSetAuth, useVerifyToken } from '../../hooks/useAuth';
import { AuthContext } from '../../App';

import CTAButton from '../shared/CTAButton';
import FullStory from '../../lib/utils/full_story';
import UserTokenVerification from '../shared/UserTokenVerification';

const fs = new FullStory();

export default function LoginTokenForm() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [verified, setVerified] = useState();
  const verify = useVerifyToken();
  const params = useParams();
  const navigate = useNavigate();
  const auth = useContext(AuthContext);

  useSetAuth(verified);

  useEffect(() => {
    if (auth.auth && auth.auth.token.access_token) {
      setLoading(true);
      navigate('/');
    }
  }, [auth, navigate]);

  const onSubmit = async (givenToken) => {
    setLoading(true);
    setError(false);
    const verified = await verify.mutateAsync({
      type: params.type,
      contact: params.contact,
      verification_code: givenToken,
    });

    if (typeof fs !== 'undefined') {
      fs.identify(verified.email, { displayName: verified.name });
      fs.event({ user: verified });
    }

    if (verified.data.locked) {
      navigate('/user_lockout');
    } else if (verified.data.authorized) {
      setVerified(verified.data);
    } else {
      setLoading(false);
      setError(true);
    }
  };

  if (!params.type && !params.contact) {
    return (
      <div role="alert" className="notification is-danger">
        Something went wrong, please
        <Link to="/login" className="mx-1">
          go back
        </Link>
        and try again.
      </div>
    );
  }

  return (
    <div
      className="min-h-full flex flex-col justify-center xxs:py-0 lg:py-4 sm:px-6 lg:px-8"
      role={LoginTokenForm.role}
    >
      <div className="mt-8 xxs:mx-auto xxs:w-full xxs:max-w-lg">
        <div className="bg-white my-4 xxs:py-10 sm:py-16 lg:py-20 px-10 xxs:shadow xxs:rounded-lg sm:px-14">
          <h2 className="my-2 text-3xl font-avenir-black text-navy-blue text-center">
            We just texted you
          </h2>
          <p className="my-2 text-base font-avenir-book text-opaque-navy text-center">
            Please enter the verification code we sent to
            <span className="ml-1 text-navy-blue font-avenir-medium block">
              {params.contact}.
            </span>
          </p>
          <UserTokenVerification onSubmit={onSubmit} />
          {!loading && error && (
            <div className="bg-negative-red bg-opacity-50 border-l-4 border-negative-red p-4 rounded-md">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <FontAwesomeIcon
                    icon={['far', 'triangle-exclamation']}
                    className="pt-1 text-lg text-negative-red"
                  />
                </div>
                <div className="ml-3">
                  <p className="text-base font-avenir-medium text-negative-red">
                    Invalid Token
                  </p>
                </div>
              </div>
            </div>
          )}
          <div className="flex flex-col 3xs:flex-row justify-between items-center w-full my-6">
            <p className="text-sm xxs:text-base text-navy-blue">
              Didn't receive the code?
            </p>
            <button className="text-xs xxs:text-sm font-avenir-medium rounded-full px-2 py-1 border border-primary-blue border-opacity-40 text-primary-blue hover:bg-primary-blue hover:border-opacity-5 hover:bg-opacity-20">
              <Link to="/login" className="ml-1">
                Change Number
              </Link>
            </button>
          </div>
          <CTAButton
            ariaLabel="login-button"
            buttonClass="w-full bg-primary-blue text-white rounded-full py-3 px-6 cursor-pointer text-sm md:text-base inline-block hover:bg-navy-blue flex justify-center items-center"
            buttonText="Continue"
            icon={['far', 'lock']}
            disabled={loading}
            isLoading={loading}
          />
        </div>
      </div>
    </div>
  );
}

LoginTokenForm.role = 'login-token-component';
