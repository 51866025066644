export default function PageHeader({ title }) {
  return (
    <div
      className="flex items-center justify-between px-4 md:px-0 mb-6 md:mb-12"
      role={PageHeader.role}
    >
      <div className="font-avenir-medium text-navy-blue text-2xl md:text-5xl">{title}</div>
    </div>
  );
}
PageHeader.role = 'page-header-component';
