import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Support from '../shared/Support';

export default function LockedOut() {
  return (
    <div id="content" className="text-center my-6">
      <div className="text-3xl font-avenir-black text-negative-red mb-4">
        <FontAwesomeIcon icon="circle-xmark" />
        <p className="mt-4">Max Attempts Exceeded</p>
      </div>

      <div className="max-w-lg mx-auto leading-7 font-avenir-medium my-4">
        <p>
          We're sorry, an error has occurred and your account has been locked.
        </p>
        <p>
          Please contact Credit Key technical support to unlock your account.
        </p>
      </div>
      <Support />
    </div>
  );
}
