import { useContext, useEffect } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { ApiContext } from '../App';
import { getCompany } from '../actions/companies';
import { useEntities } from './useEntities';

function useCompany(user) {
  const api = useContext(ApiContext);
  const queryClient = useQueryClient();
  const { clearMany, setOne } = useEntities();

  const {
    data: company,
    error: companyError,
    isLoading: companyLoading,
    isRefetching: companyRefetching,
  } = useQuery({
    queryKey: ['company', user?.company_id],
    queryFn: () => getCompany(api, user?.company_id),
    enabled: !!user,
    retry: false,
  });

  const refetchCompany = () => {
    queryClient.invalidateQueries(['company', user?.company_id]);
  };

  useEffect(() => {
    if (company) {
      clearMany('company');
      setOne(company);
    }
  }, [company]);

  return {
    company: company?.attributes,
    companyError,
    companyLoading,
    companyRefetching,
    refetchCompany,
  };
}

export default useCompany;
