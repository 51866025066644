import React from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Error({ errors, containerClass }) {
  return (
    <div className={classNames('rounded-md bg-red-50 p-4', containerClass)}>
      <div className="flex items-start">
        <div className="flex-shrink-0">
          <FontAwesomeIcon
            icon="circle-xmark"
            className="text-2xl text-red-400 mt-2"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3 flex flex-col items-start">
          <h3 className="text-sm text-red-800">
            There were errors with your request:
          </h3>
          <ul className="pl-6 my-1 text-sm text-red-700 list-disc">
            {errors.map((e, idx) => {
              if (!e) return '';
              return (
                <li key={idx} className="text-left capitalize">
                  {e.response.data.error}
                </li>
              );
            })}
          </ul>
          <p className="text-sm text-red-800">
            Please contact Credit Key support for further assistance.
          </p>
        </div>
      </div>
    </div>
  );
}