import React from 'react';
import classNames from 'classnames';

import Input from './Input';
import { ErrorMessage } from 'formik';
import { formStyles } from '../../lib/constants/SiteVariables';

export default function StickyInput(props) {
  if (!props.id || !props.placeholder) throw 'Missing required props';

  return (
    <div
      className="form-floating flex flex-col relative"
      style={props.style}
    >
      <Input {...props} />
      <label
        htmlFor={props.id}
        className={classNames(
          'text-gray-500 absolute duration-300 origin-0 px-4 3xs:px-3.5 text-sm',
          props?.values[props.id]
            ? 'uppercase text-xs top-3'
            : 'top-4 3xs:top-5',
        )}
      >
        {props.placeholder}
      </label>
      <ErrorMessage
        name={props.id}
        component="div"
        className={formStyles.validation}
      />
    </div>
  );
}
