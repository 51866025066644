import React, { useContext, useEffect, useState } from 'react';
import { Menu } from '@headlessui/react';
import { useMutation } from '@tanstack/react-query';

import CTAButton from '../shared/CTAButton';
import Dropdown from '../shared/Dropdown';
import useToast from '../../hooks/useToast';
import { ApiContext } from '../../App';
import { updateCompany } from '../../actions/companies';
import { useEntities } from '../../hooks/useEntities';

export default function DefaultTermSettings({
  canNextScreen,
  nextScreen,
  refetchCompany,
  toggleModal,
  loading,
  setLoading,
  modalState: { company, merchant },
}) {
  const api = useContext(ApiContext);
  const { state } = useEntities();
  const { makeToast } = useToast();
  const [term, setTerm] = useState();
  const [error, setError] = useState(false);
  const terms = state.get('approved_term').valueSeq().toJS();

  const termDisplay = (term) => {
    if(!term) return null;
    return term === 1 ? `${term} month` : `${term} months`;
  };

  useEffect(() => {
    if (term) setError(false);
  }, [term]);

  const saveDefaultSettings = useMutation({
    mutationFn: async ({ api }) => {
      return updateCompany(api, {
        ...company,
        recharge_settings: [
          ...company?.recharge_settings,
          { default_term: term?.term, merchant_id: merchant?.id },
        ],
      });
    },
    onError: () => {
      makeToast(
        'An error occurred. Please contact Credit Key Support',
        'bg-negative-red',
        ['far', 'octagon-xmark'],
      );
    },
    onSuccess: async () => {
      makeToast(
        'Instore Settings Successfully Updated',
        'bg-positive-green',
        'check-circle',
      );
      if (canNextScreen) {
        nextScreen();
      } else {
        toggleModal();
        refetchCompany();
      }
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  const onSave = async () => {
    if (!term) setError(true);

    if (term?.term && merchant?.id) {
      setLoading(true);
      await saveDefaultSettings.mutateAsync({ api });
    }
  };

  return (
    <div>
      <div className="flex items-center justify-center px-4 text-center sm:block sm:px-0 max-w-md max-h-md">
        <div className="mt-3 text-center">
          <div className="mx-6 text-center text-navy-blue">
            <p className="text-lg my-2 font-avenir-black">
              Default Terms for {merchant?.name}
            </p>
            <p className="my-2">
              Here you can set the default term length for future{' '}
              {merchant?.name} in-store purchases
            </p>
          </div>
          <div className="my-6 text-center">
            <Dropdown
              buttonText={termDisplay(term?.term) || 'Select a Default Term'}
            >
              {terms?.map((term, idx) => {
                return (
                  <Menu.Item key={idx}>
                    {({ active }) => (
                      <button
                        onClick={() => setTerm(term)}
                        className={`${
                          active
                            ? 'bg-primary-blue text-white rounded-md'
                            : 'text-gray-900'
                        } group flex w-full items-center px-4 py-2 text-sm`}
                      >
                        {termDisplay(term?.term)}
                      </button>
                    )}
                  </Menu.Item>
                );
              })}
            </Dropdown>
            {error && (
              <div className="text-xs text-negative-red my-2">
                * Please select a default term
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="sm:mb-8 my-2 w-full m-auto flex justify-center items-center">
        <CTAButton
          buttonClass="bg-primary-blue text-white"
          buttonText="Save"
          containerClass="mx-2 w-1/3"
          icon={['far', 'lock']}
          onClick={onSave}
          disabled={loading}
          isLoading={loading}
          role="save-default-settings-btn"
        />
        <CTAButton
          ariaLabel="Close"
          buttonClass="px-14 bg-light-blue text-opaque-navy hover:bg-gray-500 hover:text-white"
          containerClass="mx-2 w-1/3"
          buttonText="Cancel"
          onClick={toggleModal}
        />
      </div>
    </div>
  );
}

DefaultTermSettings.role = 'default-term-settings-modal-screen';
