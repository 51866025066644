import { useCallback, useEffect, useState } from 'react';

function useModal(availableScreens = []) {
  const [isModalActive, setIsModalActive] = useState(false);
  const [activeScreen, setActiveScreen] = useState();
  const [screenIndex, setScreenIndex] = useState(0);

  const toggleModal = useCallback(
    () => setIsModalActive((isModalActive) => !isModalActive),
    [],
  );

  const canNextScreen = screenIndex + 1 < availableScreens.length;

  const nextScreen = () => {
    const newIndex = screenIndex + 1;
    if (newIndex < availableScreens.length) {
      setScreenIndex(newIndex);
    }
  };

  useEffect(() => {
    setActiveScreen(availableScreens[screenIndex]);
  }, [screenIndex, availableScreens]);

  return {
    activeScreen,
    nextScreen,
    isModalActive,
    toggleModal,
    canNextScreen,
  };
}

export default useModal;
