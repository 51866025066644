import { DateTime } from 'luxon';
import { apiResponse, apiResponses } from './api_response';
import { rest } from 'msw';

const auth = {
  verify: () =>
    rest.post(
      process.env.REACT_APP_API + '/api/users/verify',
      (req, res, ctx) => {
        return res(ctx.json({ require_pin_verification: true }));
      },
    ),

  verified: (user, verification_code) =>
    rest.post(
      process.env.REACT_APP_API + '/api/users/verified',
      (req, res, ctx) => {
        return res(
          ctx.json({
            invalid_code: null,
            locked: null,
            authorized: verification_code === 273338 ? true : false,
            user: user,
            token: {
              access_token: 'CKTESTTOKEN',
              token_type: 'bearer',
              expires_in: DateTime.now().plus({ hours: 8 }).toMillis() / 1000,
              refresh_token: null,
              created_at: DateTime.now().toMillis() / 1000,
            },
          }),
        );
      },
    ),
};

const get = {
  company: (company) =>
    rest.get(
      process.env.REACT_APP_API + '/api/companies/' + company.id,
      (req, res, ctx) => {
        return res(
          ctx.json(apiResponse({ ...company.toJS(), type: 'company' })),
        );
      },
    ),

  merchant: (merchant) =>
    rest.get(
      process.env.REACT_APP_API + '/api/merchants/' + merchant.id,
      (req, res, ctx) => {
        return res(
          ctx.json(apiResponse({ ...merchant.toJS(), type: 'merchant' })),
        );
      },
    ),

  payment_methods: (user, payment_methods) =>
    rest.get(
      process.env.REACT_APP_API + '/api/payment_methods',
      (req, res, ctx) => {
        return res(
          ctx.json(apiResponses(payment_methods, { type: 'payment_method' })),
        );
      },
    ),
};

const post = {
  approved_terms: (company, merchant, approved_terms) =>
    rest.post(
      process.env.REACT_APP_API + `/api/companies/${company.id}/approved_terms`,
      (req, res, ctx) => {
        return res(
          ctx.json({
            data: {
              attributes: { terms: approved_terms },
              type: 'approved_term',
            },
          }),
        );
      },
    ),

  virtual_card: (order) =>
    rest.post(
      process.env.REACT_APP_API + '/api/orders/vc_instore',
      (req, res, ctx) => {
        const include = req.url.searchParams.get('include');
        if (!include) {
          return res(
            ctx.status(404),
            ctx.json({
              error: 'Not found',
            }),
          );
        }
        return res(ctx.json(apiResponse(order)));
      },
    ),

  payment_method: (payment_method) => {
    return rest.post(
      process.env.REACT_APP_API + `/api/payment_methods/bank_account`,
      (req, res, ctx) => {
        return res(
          ctx.json(
            apiResponse({ ...payment_method.toJS(), type: 'payment_method' }),
          ),
        );
      },
    );
  },

  generate_bank_agreement: (user) =>
    rest.post(
      process.env.REACT_APP_API +
        '/api/payment_methods/0/generate_bank_agreement',
      (req, res, ctx) => {
        return res(
          ctx.json({
            type: 'bank_agreement',
            attributes: {
              data: '<h1>Test Agreement</h1>',
            },
          }),
        );
      },
    ),
};

const put = {
  company: (company) => {
    return rest.put(
      process.env.REACT_APP_API + `/api/companies/` + company.id,
      (req, res, ctx) => {
        return res(
          ctx.json(apiResponse({ ...company.toJS(), type: 'company' })),
        );
      },
    );
  },
};

export { auth, get, post, put };
