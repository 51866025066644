import React from 'react';
import { Dialog } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { supportPhone } from '../../../lib/constants/SiteVariables';

export default function BankError({ toggleModal }) {
  return (
    <div role={BankError.role}>
      <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-negative-red/30">
        <FontAwesomeIcon
          icon={['far', 'exclamation-triangle']}
          className="text-negative-red text-xl"
        />
      </div>
      <div className="my-3 text-center sm:my-5">
        <Dialog.Title
          as="h3"
          className="text-2xl font-avenir-medium leading-6 text-negative-red"
        >
          An Error Occurred
        </Dialog.Title>
        <div className="my-4 mx-2">
          <p>We're having some trouble validating your bank information.</p>
          <p>
            Please use an alternative payment method to checkout <br />
            or contact Credit Key Support.
          </p>
          <p className="mt-2">
            <a href="mailto: support@creditkey.com" className="underline mx-1">
              support@creditkey.com
            </a>
            or {supportPhone}
          </p>
        </div>
      </div>
      <div className="my-5 sm:my-6 w-3/4 mx-auto">
        <button
          type="button"
          className="inline-flex w-full justify-center rounded-md bg-navy-blue px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-navy-blue/70"
          onClick={toggleModal}
        >
          Close
        </button>
      </div>
    </div>
  );
}


BankError.role = 'bank-error-component';