import { fromJS, OrderedMap } from 'immutable';
import * as ActionTypes from '../lib/constants/action-types';
import models from '../lib/constants/models';

function setState(state, newState) {
  return state.merge(newState);
}

export const entitiesState = fromJS(models).map(m => new OrderedMap());

export default function entities(state, action) {
  switch (action.type) {
    case ActionTypes.CLEAR_ENTITIES:
      if (action.payload) {
        return setState(state, state.set(action.payload, entitiesState.get(action.payload)));
      }
      return entitiesState;

    case ActionTypes.CLEAR_ENTITY:
      if (!action.payload) return state;
      return setState(state, state.deleteIn([action.entity ? action.entity : action.payload.type, action.payload.id]));

    case ActionTypes.RECEIVE_ENTITIES:
      if (action.payload.data && action.payload.data.length < 1) return state;

      action.payload.data.map(resource => state = setState(state, state
        .setIn([resource.type, resource.id], new models[resource.type](fromJS({ ...{ id: resource.id }, ...resource.attributes })))));

      return state;

    case ActionTypes.RECEIVE_ENTITY:
      return setState(state, state
        .setIn([action.payload.type, action.payload.id], new models[action.payload.type](fromJS({ ...{ id: action.payload.attributes.id }, ...action.payload.attributes }))));

    default:
      return state;

  }
}