import React from 'react';
import classNames from 'classnames';
import { formStyles } from '../../lib/constants/SiteVariables';

export default function ColumnInput({
  children,
  error,
  touched,
  className
}) {
  const multi = Array.isArray(children);
  const columnClass = 'flex flex-col justify-center';

  return (
    <div className={classNames(formStyles.input(touched && error), className)}>
      {multi ?
        children
          .map((c, key) => <div key={key} className={c.props.className ? columnClass + ' ' + c.props.className : columnClass}>
            {c.props.error ? c : React.cloneElement(c, { error })}
          </div>
          ) : (
          <div className="w-full">{React.cloneElement(children, { error, touched })}</div>
        )}
    </div>
  );
}