import { Record } from 'immutable';

export default class CreditLineRecord extends Record({
  status: null,
  amount: 0,
  remaining_amount: 0,
}) {
  get total_used() {
    return this.amount - this.remaining_amount;
  }
}
