import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Popover, Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AuthButton from './AuthButton';

export default function MobileNavButton() {
  return (
    <Popover
      className="relative bg-white inline-block"
      role={MobileNavButton.role}
    >
      <div>
        <div className="flex justify-start items-center border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10 mb-8 px-6">
          <div className="-mr-2 -my-2">
            <Popover.Button className="bg-white text-navy-blue rounded-md p-2 inline-flex items-center justify-center hover:bg-gray-100">
              <span className="sr-only">Open menu</span>
              <FontAwesomeIcon icon={['far', 'bars']} className="text-lg" />
            </Popover.Button>
          </div>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="z-50 absolute top-0 right-0 p-2 transition transform origin-top-right min-w-72 3xs:min-w-80 xs:min-w-96"
        >
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-light-blue divide-y-2 divide-gray-100">
            <div className="py-2 px-3">
              <div className="flex justify-between items-center">
                <Link
                  to="https://creditkey.zendesk.com/hc/en-us"
                  className="flex items-center justify-center p-1 text-primary-blue rounded-full hover:text-navy-blue pr-2 md:pr-4"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon
                    icon={['fal', 'circle-question']}
                    className="mr-1.5 mb-0.5 shrink-0 min-w-[24px] cursor-pointer text-xl"
                  />
                  <p>Credit Key Support</p>
                </Link>
                <Popover.Button className="text-navy-blue rounded-md p-2 inline-flex items-center justify-center hover:bg-opaque-navy-border">
                  <span className="sr-only">Close menu</span>
                  <FontAwesomeIcon
                    icon={['far', 'xmark']}
                    className="text-xl"
                  />
                </Popover.Button>
              </div>
            </div>
            <div className="py-6 px-5 space-y-6">
              <Popover.Button
                as="div"
                className="flex items-center cursor-pointer hover:bg-white transition"
              >
                <AuthButton />
              </Popover.Button>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}

MobileNavButton.role = 'mobile-navigation-button';
