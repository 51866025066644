import React, { Fragment } from 'react';
import Cards from 'react-credit-cards-2';
import { useNavigate } from 'react-router';
import { Dialog, Transition } from '@headlessui/react';
import 'react-credit-cards-2/dist/es/styles-compiled.css';

import CTAButton from '../../shared/CTAButton';
import Loading from '../../shared/Loading';
import Support from '../../shared/Support';

export default function CreditCardDisplay({
  showCard,
  toggleCardDisplay,
  virtualCardDetails,
}) {
  const navigate = useNavigate();
  const { cvc, expiration, name, number } = { ...virtualCardDetails };

  return (
    <Transition appear show={showCard} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={toggleCardDisplay}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full flex flex-col items-center max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div data-testid="virtual-card-wrapper">
                  {!virtualCardDetails ? (
                    <Loading
                      className="w-14 h-14"
                      containerClass="w-full flex justify-center items-center min-h-lg"
                    />
                  ) : (
                    <div className="flex flex-col gap-3 my-3">
                      <Cards
                        cvc={cvc}
                        expiry={expiration}
                        name={name}
                        number={number}
                        focused="number"
                        className="text-navy-blue"
                      />
                      <Cards
                        cvc={cvc}
                        expiry={expiration}
                        name={name}
                        number={number}
                        focused="cvc"
                      />
                    </div>
                  )}
                </div>
                <p className='font-avenir-book text-sm w-full xs:w-3/4 text-center my-4'>
                  This virtual card is designed for a single transaction and
                  will be unavailable once you close this window. <br />Ensure you
                  have used or saved the card details before proceeding.
                </p>
                <CTAButton
                  ariaLabel="close-vc-modal"
                  buttonClass="bg-primary-blue text-white py-2"
                  buttonText="Close and Discard"
                  containerClass="my-2 lg:my-4 w-full xs:w-2/5 sm:mx-auto"
                  onClick={() => {
                    navigate('/order_complete');
                    toggleCardDisplay();
                  }}
                />
                <div className="font-avenir-medium text-center mt-6 m-4">
                  <Support />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
