import React from 'react';
import { AccountStatus } from './helpers';

export default function NotApproved({ company }) {
  return (
    <div>
      <dd className="px-0 md:px-4 text-base leading-6 text-gray-500 flex flex-col md:flex-row justify-between items-center">
        <div>
          <span className="text-navy-blue mx-1 font-avenir-medium">
            Company Status
          </span>
        </div>
        <AccountStatus status={company?.underwriting_status} />
      </dd>
      {company?.underwriting_status !== 'approved' && (
        <p className="text-navy-blue mt-8 m-4">
          Unable to proceed. Please checkout with an alternative payment method
          or contact Credit Key support for further assistance.
        </p>
      )}
    </div>
  );
}
