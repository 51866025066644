import React, { useState } from 'react';
import { Formik, Form } from 'formik';

import CTAButton from '../shared/CTAButton';
import StickyInput from '../shared/StickyInput';

export default function UserVerification({
  defaultValue,
  onSubmit,
  buttonText = 'Next',
  loading,
}) {
  const [valid, setValid] = useState(false);

  return (
    <div role="form">
      <Formik
        initialValues={{
          contact: defaultValue,
        }}
        onSubmit={(values) => {
          onSubmit(values, 'phone');
        }}
      >
        {({ values }) => (
          <Form>
            <div className="my-6">
              <StickyInput
                id="contact"
                name="contact"
                type="tel"
                placeholder="Mobile Phone"
                mask={[
                  {
                    ckType: 'phone',
                    mask: '(999) 999-9999',
                    definitions: { 9: /\d/ },
                  },
                ]}
                values={values}
                dispatch={(appended, masked) => {
                  const newMask = masked.compiledMasks[0];
                  setValid(newMask.isComplete);

                  return newMask;
                }}
              />
            </div>
            <CTAButton
              ariaLabel="login-button"
              buttonClass="w-full bg-primary-blue text-white rounded-full py-3 px-6 cursor-pointer text-sm md:text-base inline-block hover:bg-navy-blue flex justify-center items-center"
              buttonText={buttonText}
              icon={['far', 'lock']}
              disabled={!valid || loading}
              isLoading={loading}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
}
