import React, { useState } from 'react';

import Error from '../../shared/Error';
import NextStepButton from './NextStepButton';
import NotApproved from './NotApproved';
import { formatCurrency } from '../../../lib/utils/formatters';

export default function AccountDetails({ props }) {
  const [loading, setLoading] = useState(false);
  const { company, error, pmError, defaultPM, rechargeSettings } = props;

  return (
    <div className="lg:col-start-3 lg:row-end-1" role={AccountDetails.role}>
      <div className="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
        <dl className="flex flex-wrap">
          <div className="px-2 xs:px-4 py-3 xs:py-6 border-b border-gray-900/5 flex items-center justify-center w-full">
            <dt className="text-xl font-avenir-medium leading-6 text-navy-blue">
              Account Summary
            </dt>
          </div>
          {error || pmError ? (
            <Error errors={[error, pmError]} containerClass="w-full" />
          ) : (
            <div className="p-3 xs:p-6 flex flex-col gap-6 w-full">
              <dd className="px-0 md:px-4 text-base leading-6 text-gray-500 flex flex-col md:flex-row justify-between items-center">
                <div>
                  <span className="text-navy-blue mx-1 font-avenir-medium">
                    Company Name
                  </span>
                </div>
                <span>{company?.name}</span>
              </dd>
              <dd className="px-0 md:px-4 text-base leading-6 text-gray-500 flex flex-col md:flex-row justify-between items-center">
                <div>
                  <span className="text-navy-blue mx-1 font-avenir-medium">
                    Owner Name
                  </span>
                </div>
                <span>
                  {company?.borrower?.first_name} {company?.borrower?.last_name}
                </span>
              </dd>
              {company?.underwriting_status !== 'approved' ? (
                <NotApproved company={company} />
              ) : (
                <>
                  <dd className="px-0 md:px-4 text-base leading-6 text-gray-500 flex flex-col md:flex-row justify-between items-center">
                    <div>
                      <span className="text-navy-blue mx-1 font-avenir-medium">
                        Available Credit Amount
                      </span>
                    </div>
                    <span>
                      {formatCurrency(company?.credit_line?.remaining_amount)}
                    </span>
                  </dd>
                  <dd className="px-0 md:px-4 text-base leading-6 text-gray-500 flex flex-col md:flex-row justify-between items-center">
                    <div>
                      <span className="text-navy-blue mx-1 font-avenir-medium">
                        Default Bank Account
                      </span>
                    </div>
                    <span>
                      {defaultPM ? (
                        defaultPM?.account_holder
                      ) : (
                        <span className="text-negative-red">
                          Settings Needed
                        </span>
                      )}
                    </span>
                  </dd>
                  <dd className="px-0 md:px-4 text-base leading-6 text-gray-500 flex flex-col md:flex-row justify-between items-center">
                    <div>
                      <span className="text-navy-blue mx-1 font-avenir-medium">
                        Default Term
                      </span>
                    </div>
                    <span>
                      {rechargeSettings ? (
                        <span>
                          {rechargeSettings?.default_term} month
                          {rechargeSettings?.default_term > 1 ? 's' : ''}
                        </span>
                      ) : (
                        <span className="text-negative-red">
                          Settings Needed
                        </span>
                      )}
                    </span>
                  </dd>
                </>
              )}
            </div>
          )}
        </dl>
        <div className="border-t border-gray-900/5 p-4 xs:p-6">
          <NextStepButton
            {...props}
            loading={loading}
            setLoading={setLoading}
          />
        </div>
      </div>
    </div>
  );
}


AccountDetails.role = 'account-details-component';