import React from 'react';
import Support from './Support';

export default function Unauthorized() {
  return (
    <main
      className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8"
      id="content"
    >
      <div className="text-center">
        <p className="text-3xl font-avenir-black text-primary-blue">404</p>
        <h1 className="my-4 text-3xl font-avenir-black tracking-tight text-gray-900 sm:text-5xl">
          Unauthorized
        </h1>
        <p className="text-base">Sorry, this isn't a valid Credit Key page.</p>
        <p className="text-sm text-gray-600">
          Please check your URL or contact Credit Key Support.
        </p>
        <div className="mt-10">
          <Support />
        </div>
      </div>
    </main>
  );
}
