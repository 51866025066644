import ApprovedTermRecord from '../../../records/approved_term';
import CompanyRecord from '../../../records/company';
import CreditLineRecord from '../../../records/credit_line';
import MerchantRecord from '../../../records/merchant';
import PaymentMethodRecord from '../../../records/payment_method';
import UserRecord from '../../../records/user';

import { List } from 'immutable';

const user = new UserRecord({
  id: '5dd38c8cd5691b6c81096c58',
  company_id: 'company123',
  account_id: 'account123',
  first_name: 'Test',
  last_name: 'User',
  email: 'test@creditkey.com',
  phone: '19895551234',
  role: 'borrower',
});

const companyApproved = new CompanyRecord({
  id: user.company_id,
  name: 'VC Company Co.',
  underwriting_status: 'approved',
  tier: 'tier_y',
  is_past_due: false,
  min_rate: 1.0,
  max_rate: 1.2,
  credit_line: new CreditLineRecord({
    amount: 20000.0,
    remaining_amount: 15000.0,
  }),
  is_bank_connected: true,
  is_bank_connection_pending: false,
  borrower: user,
  recharge_settings: [
    {
      merchant_id: 'trs',
      default_term: '12',
    },
  ],
});

const companyNoRecharge = new CompanyRecord({
  id: user.company_id,
  name: 'Widget Maker Co.',
  underwriting_status: 'approved',
  tier: 'tier_y',
  is_past_due: false,
  min_rate: 1.0,
  max_rate: 1.2,
  credit_line: new CreditLineRecord({
    amount: 20000.0,
    remaining_amount: 15000.0,
  }),
  is_bank_connected: true,
  is_bank_connection_pending: false,
  borrower: user,
  recharge_settings: [],
});

const paymentMethodBank1 = new PaymentMethodRecord({
  id: 'pm-bank-1',
  user_id: user.id,
  account_id: null,
  payment_type: 'bank_account',
  label: 'My Big Bank',
  account_holder: 'James A Borrower',
  bank_name: 'Mega Corp Bank',
  bank_account: '676758584949',
  bank_routing: '673890178',
  status: 'active',
  is_default: true,
});

const paymentMethodBank2 = new PaymentMethodRecord({
  id: 'pm-bank-2',
  user_id: user.id,
  account_id: null,
  payment_type: 'bank_account',
  label: 'My Big Bank',
  account_holder: 'James A Borrower',
  bank_name: 'Mega Corp Bank',
  bank_account: '676758584949',
  bank_routing: '673890178',
  status: 'active',
  is_default: false,
});

const vcMerchant = new MerchantRecord({
  id: 'trs',
  name: 'CK Virtual Card',
  promos: new List(),
  supports_vcinstore: true,
});

const nonVCmerchant = new MerchantRecord({
  id: 'merchant-a',
  name: 'Merchant A',
});

const vcOrder = {
  id: 'vcOrderA',
  entity_id: companyApproved.id,
  entity_type: 'company',
  created_at: '2024-02-101T22:35:51.317-08:00',
  merchant_order_id: 'VCORDER-123',
  status: 'current',
  amount: 1,
  product_type: 'tps_loan',
  merchant: vcMerchant,
  transaction_type: 'virtual_card',
  virtual_card_details: {
    name: vcMerchant.name,
    number: '1234567890123456',
    expiration: '10/2026',
    cvc: '123',
  },
};

const approvedTerm1 = new ApprovedTermRecord({
  id: 1,
  available: true,
  term: 1,
  term_period: 'monthly',
  tier: 'tier_1',
  rate: 1.0,
  total_fees: '0.00',
  monthly_payment: '0.00',
  total_amount: '0.00',
});

const approvedTerm2 = new ApprovedTermRecord({
  id: 2,
  available: true,
  term: 2,
  term_period: 'monthly',
  tier: 'tier_1',
  rate: 1.0,
  total_fees: '0.00',
  monthly_payment: '0.00',
  total_amount: '0.00',
});

const approvedTerm3 = new ApprovedTermRecord({
  id: 3,
  available: true,
  term: 3,
  term_period: 'monthly',
  tier: 'tier_1',
  rate: 1.05,
  total_fees: '0.00',
  monthly_payment: '0.00',
  total_amount: '0.00',
});

const approvedTerm4 = new ApprovedTermRecord({
  id: 4,
  available: true,
  term: 6,
  term_period: 'monthly',
  tier: 'tier_1',
  rate: 1.1,
  total_fees: ' 0.00',
  monthly_payment: '0.00',
  total_amount: '0.00',
});

const approvedTerm5 = new ApprovedTermRecord({
  id: 5,
  available: true,
  term: 9,
  term_period: 'monthly',
  tier: 'tier_1',
  rate: 1.15,
  total_fees: '0.00',
  monthly_payment: '0.00',
  total_amount: '0.00',
});

const approvedTerm6 = new ApprovedTermRecord({
  id: 6,
  available: true,
  term: 12,
  term_period: 'monthly',
  tier: 'tier_1',
  rate: 1.2,
  total_fees: '0.00',
  monthly_payment: '0.00',
  total_amount: '0.00',
});

export {
  user,
  companyApproved,
  companyNoRecharge,
  vcMerchant,
  nonVCmerchant,
  paymentMethodBank1,
  paymentMethodBank2,
  vcOrder,
  approvedTerm1,
  approvedTerm2,
  approvedTerm3,
  approvedTerm4,
  approvedTerm5,
  approvedTerm6,
};
