import React from 'react';
import classNames from 'classnames';

import { MaskedField } from './MaskedField';
import { formStyles } from '../../lib/constants/SiteVariables';

export default function Input(props) {
  return (
    <MaskedField
      {...props}
      name={props.id}
      className={classNames(
        'w-full px-3.5 block text-navy-blue bg-transparent border border-solid border-opaque-navy-border block rounded-lg appearance-none',
        formStyles.error(props.touched && props.error),
        props?.values[props.id]
          ? 'pt-7 pb-1 3xs:pb-3'
          : 'pt-4 3xs:pt-4 pb-1 3xs:pb-3',
      )}
      id={props.id}
      placeholder=" "
    />
  );
}
