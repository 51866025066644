import React from 'react';
import { Popover } from '@headlessui/react';

import BankForm from './BankForm';
import check from '../../../lib/images/check.gif';

export default function BankAccountDetails({
  nextScreen,
  toggleModal,
  refetchCompany,
}) {
  return (
    <div>
      <div className="flex items-center justify-center px-4 text-center sm:block sm:px-0 max-w-md max-h-md mx-auto">
        <div className="mt-3 text-center">
          <div className="mx-6 my-2 text-center text-navy-blue">
            <p className="text-lg font-avenir-black">
              Add Default Bank Account
            </p>
            <p>Please confirm your business bank details.</p>
            <Popover className="relative">
              <Popover.Button className="text-xs text-center text-primary-blue underline cursor-pointer">
                Need Help?
              </Popover.Button>

              <Popover.Panel className="absolute left-20 z-10 bg-navy-blue text-white p-4 rounded-md w-80 h-50">
                <img src={check} alt="example check" className="mx-auto" />
              </Popover.Panel>
            </Popover>
          </div>
        </div>
      </div>
      <BankForm
        toggleModal={toggleModal}
        refetchCompany={refetchCompany}
        nextScreen={nextScreen}
      />
    </div>
  );
}

BankAccountDetails.role = 'add-bank-account-modal-screen';
