import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Disclosure } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import logo from '../../lib/images/logo.svg';
import MobileNavButton from './MobileNavButton';
import { UserContext } from '../../App';

export default function TopNav() {
  const user = useContext(UserContext);

  return (
    <Disclosure as="nav" className="bg-white shadow" role={TopNav.role}>
      <div className="mx-auto px-2 sm:px-4 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex px-2 lg:px-0">
            <div className="flex-shrink-0 flex items-center mx-6">
              <img src={logo} alt="Credit Key Logo" />
            </div>
          </div>
          {user?.user ? (
            <MobileNavButton />
          ) : (
            <Link
              to="https://creditkey.zendesk.com/hc/en-us"
              className="flex items-center justify-center p-1 text-primary-blue rounded-full hover:text-navy-blue pr-2 md:pr-4"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon
                title="circle-question-icon"
                icon={['fal', 'circle-question']}
                className="mr-1.5 mb-0.5 shrink-0 min-w-[24px] cursor-pointer text-xl"
              />
            </Link>
          )}
        </div>
      </div>
    </Disclosure>
  );
}

TopNav.role = 'top-navigation';
