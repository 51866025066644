import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import logo from '../../../lib/images/logo.svg';
import classNames from 'classnames';

export default function ModalWrapper({
  children,
  isModalActive,
  toggleModal,
  modalHeight,
  header,
  modalOverflowSettings,
}) {
  return (
    <div role={ModalWrapper.role} className="hidden">
      <Transition.Root show={isModalActive} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 font-avenir-book"
          onClose={toggleModal}
        >
          <div className="flex items-end justify-center sm:min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative inline-block align-bottom bg-white rounded-md pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full">
                {header ? (
                  header
                ) : (
                  <div className="flex justify-center border-b border-gray-200 pb-6 my-2">
                    <img src={logo} alt="Credit Key logo" className="w-1/4" />
                  </div>
                )}
                <div className="absolute top-6 right-6">
                  <button
                    type="button"
                    className="text-white bg-opaque-navy-border rounded-full h-6 w-6 xs:h-8 xs:w-8 flex items-center justify-center hover:bg-opaque-navy"
                    onClick={toggleModal}
                  >
                    <span className="sr-only">Close</span>
                    <FontAwesomeIcon
                      icon="times"
                      className="text-xs xs:text-base"
                    />
                  </button>
                </div>
                <div
                  className={classNames(
                    'px-4 sm:px-6 sm:py-3 flex justify-center',
                    modalHeight ? modalHeight : 'max-h-modal',
                    modalOverflowSettings
                      ? modalOverflowSettings
                      : 'overflow-y-scroll',
                  )}
                >
                  {children}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

ModalWrapper.role = 'modal-wrapper-component';
