import ApprovedTermRecord from "../../records/approved_term";
import CompanyRecord from "../../records/company";
import MerchantRecord from "../../records/merchant";
import UserRecord from "../../records/user";
import PaymentMethodRecord from "../../records/payment_method";

export default {
  user: UserRecord,
  company: CompanyRecord,
  merchant: MerchantRecord,
  approved_term: ApprovedTermRecord,
  payment_method: PaymentMethodRecord,
};