export const generateBankAgreement = async (api, user) => {
  const bank_agreement = await api.post(
    '/api/payment_methods/0/generate_bank_agreement',
    {
      user_id: user?.id,
    },
  );
  return bank_agreement?.data?.attributes?.data;
};

export const createPaymentMethodBank = async (api, payment_method) => {
  return api.post('/api/payment_methods/bank_account', { payment_method });
};
