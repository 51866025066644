export const getCompany = async (api, companyID) => {
  if (companyID) {
    const company = await api.get(`/api/companies/${companyID}`);
    return company.data.data;
  }
  return null;
};

export const getCompanyPaymentMethods = async (api, filters = '') => {
  const payment_methods = await api.get(`/api/payment_methods?${filters}`);
  return payment_methods.data;
};

export const getApprovedTerms = async (api, company_id, merchant_id) => {
  const approved_terms = await api.post(
    `/api/companies/${company_id}/approved_terms`,
    { merchant_id },
  );
  return {
    data: approved_terms.data.data.attributes.terms.map((term) => {
      if (term.available)
        return { id: term.id, type: 'approved_term', attributes: term };
    }),
  };
};

export const updateCompany = (api, company) => {
  return api.put(`/api/companies/${company.id}`, company).then((res) => {
    return res.data.data;
  });
};
